<template>
    <page-title
        title="社用車一覧"
        icon="bi-truck"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <section class="section" v-else>
        <table class="table table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="col-md-5">社用車名称</th>
                    <th class="text-center col-md-3">社用車略称</th>
                    <th class="text-center col-md-4">ナンバー</th>
                    <th class="text-center col-md-3">車検期限</th>
                    <th class="text-center col-md-3">年齢制限</th>
                    <th class="text-center col-md-3">並び順</th>
                    <th class="text-center col-md-3"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="car, index in cars" :key="car">
                    <td class="">
                        {{ car.car_name }}
                    </td>
                    <td class="text-center">
                        {{ car.shortened_name }}
                    </td>
                    <td class="text-center">
                        {{ car.car_number }}
                    </td>
                    <td class="text-center">
                        {{ car.expired_date_display }}
                    </td>
                    <td class="text-center">
                        {{ car.age_limit }}
                    </td>
                    <td class="text-center">
                        {{ car.priority }}
                    </td>
                    <td class="text-center">
                        <button-exec
                            text="編集..."
                            icon="bi-box-arrow-up-left"
                            @click="openModal(car, index)"
                        ></button-exec>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="ms-2">
            <button-exec
                text="新規登録..."
                icon="bi-plus-lg"
                @click="openModal()"
            ></button-exec>
        </div>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    {{ modal_title }}
                                </h4>
                                <button type="button" class="btn-close" @click="cancelEdit"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="save" id="modal_form">
                                    <div class="row mb-5">
                                        <div class="form-group col-md-6">
                                            <label>社用車名称</label>
                                            <form-input
                                                v-model="modal_car.car_name"
                                                :required="true"
                                            ></form-input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>社用車略称</label>
                                            <form-input
                                                v-model="modal_car.shortened_name"
                                                :required="true"
                                            ></form-input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>並び順</label>
                                            <form-input-number
                                                v-model="modal_car.priority"
                                                min="0"
                                                max="9999"
                                                :required="true"
                                                step="1"
                                            ></form-input-number>
                                        </div>
                                    </div>
                                    <div class="row mb-5">
                                        <div class="form-group col-md-6">
                                            <label>ナンバー</label>
                                            <form-input
                                                v-model="modal_car.car_number"
                                            ></form-input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>車検期限</label>
                                            <form-input-date
                                                v-model="modal_car.expired_date_for_input"
                                            ></form-input-date>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>年齢制限</label>
                                            <form-input
                                                v-model="modal_car.age_limit"
                                            ></form-input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button-exec-create
                                    v-if="!modal_car.car_id"
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-create>
                                <button-exec-update
                                    v-else
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-update>
                                <button-exec-delete
                                    v-if="modal_car.car_id"
                                    size="btn-lg"
                                    @click="$refs.confirm_remove.show()"
                                ></button-exec-delete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import InlineLoader from '@/components/tools/InlineLoader';
import Car from '@/models/entities/car';
import FormInput from '@/components/forms/FormInput.vue';
import FormInputNumber from '@/components/forms/FormInputNumber.vue';
import FormInputDate from '@/components/forms/FormInputDate.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate.vue';


export default {
    name: 'Car',
    components: {
        PageTitle,
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormInputNumber,
        FormInputDate,
        ButtonExec,
        ButtonExecDelete,
        ButtonExecCreate,
        ButtonExecUpdate,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            cars: [],
            modal: false,
            modal_car: new Car(),
            original_car: new Car(),
            modal_index: null,
            modal_title: null,
        }
    },
    mounted() {
        this.fetchCarList();
    },
    methods: {
        fetchCarList() {
            this.loading++;
            this.$http.get(`/cars`)
            .then(response => {
                for (let row of response.data) {
                    this.cars.push(new Car(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        openModal(car = null, index = null) {
            this.modal = true;
            this.modal_index = index;
            if (car) {
                this.modal_title = '社用車編集';
                this.modal_car = car;
                // オブジェクトを値渡しに変える処理
                this.original_car = JSON.stringify(car);
                this.original_car = JSON.parse(this.original_car);

            } else {
                this.modal_title = '社用車登録';
                this.modal_car = new Car();
                this.modal_car.priority = 9999;
            }

        },
        cancelEdit() {
            if (this.modal_car.car_id) {
                let target_index = this.cars.findIndex(car => car.car_id === this.original_car.car_id);

                this.cars[target_index].car_name = this.original_car.car_name;
                this.cars[target_index].shortened_name = this.original_car.shortened_name;
                this.cars[target_index].age_limit = this.original_car.age_limit;
                this.cars[target_index].car_number = this.original_car.car_number;
                this.cars[target_index].expired_date = this.original_car.expired_date;
                this.cars[target_index].priority = this.original_car.priority;

                this.original_car = new Car();
            }
            this.closeModal();
        },
        closeModal() {
            this.modal = false;
        },
        save() {
            if (this.modal_car.car_id) {
                this.startScreenLoading();
                this.$http.put(`/cars/${this.modal_car.car_id}`, this.modal_car)
                .then((response) => {
                    let updated_car = new Car(response.data);

                    // 削除して追加
                    let deleteIndex = this.cars.findIndex(car => car.car_id === updated_car.car_id);
                    this.cars.splice(deleteIndex, 1);
                    this.pushAndSortByPriority(updated_car);

                    this.showMessage('編集しました');
                    this.modal_car = new Car();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            } else {
                this.startScreenLoading();
                this.$http.post(`/cars`, this.modal_car)
                .then(response => {
                    let created_car = new Car(response.data);
                    this.pushAndSortByPriority(created_car);

                    this.showMessage('登録しました');
                    this.modal_car = new Car();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            }
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/cars/${this.modal_car.car_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.cars.splice(this.modal_index, 1);
            })
            .finally(() => {
                this.endScreenLoading();
                this.closeModal();
            });
        },
        pushAndSortByPriority(saved_car) {
            let priority = saved_car.priority;
            let index = this.cars.findIndex(car => car.priority > priority);

            if (index < 0) {
                this.cars.push(saved_car);
            } else {
                this.cars.splice(index, 0, saved_car);
            }
        }
    }
}
</script>

<style scoped>

</style>
